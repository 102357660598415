import { getBrowser } from '@/helpers/getBrowser'
import ABTests from '@/helpers/ABTests'

export default {
  data() {
    return {
      email: '',
      isOpenFormSidebar: false,
      form: '',
      header: '',
      browser: '',
      isAutotest: false,
      isABTestEnabled: false,
      btnPromoTry: {
        href: '/user/organizer',
      },
      clientsSliderList: [
        {
          src: require('~/assets/img/clients/clients-bitrix.svg'),
          alt: 'Логотип bitrix',
          width: '187',
        },
        {
          src: require('~/assets/img/clients/clients-lokotech.svg'),
          alt: 'Логотип ЛокоТех',
          width: '189',
        },
        {
          src: require('~/assets/img/clients/clients-hh.svg'),
          alt: 'Логотип hh',
          width: '62',
        },
        {
          src: require('~/assets/img/clients/clients-invitro.svg'),
          alt: 'Логотип invitro',
          width: '184',
        },
        {
          src: require('~/assets/img/clients/clients-avito.svg'),
          alt: 'Логотип avito',
          width: '118',
        },
        {
          src: require('~/assets/img/clients/clients-rusagro.svg'),
          alt: 'Логотип rusagro',
          width: '132',
        },
        {
          src: require('~/assets/img/clients/clients-exmo.svg'),
          alt: 'Логотип эксмо',
          width: '75',
        },
        {
          src: require('~/assets/img/clients/clients-mts.svg'),
          alt: 'Логотип mts',
          width: '62',
        },
        {
          src: require('~/assets/img/clients/clients-samolet.svg'),
          alt: 'Логотип samolet',
          width: '181',
        },
        {
          src: require('~/assets/img/clients/clients-russlimat.svg'),
          alt: 'Логотип Русклимат',
          width: '154',
        },
        {
          src: require('~/assets/img/clients/clients-netology.svg'),
          alt: 'Логотип Нетология',
          width: '214',
        },
        {
          src: require('~/assets/img/clients/clients-optbank.svg'),
          alt: 'Логотип optbank',
          width: '184',
        },
        {
          src: require('~/assets/img/clients/clients-renessans.svg'),
          alt: 'Логотип renessans',
          width: '108',
        },
        {
          src: require('~/assets/img/clients/clients-technonicol.svg'),
          alt: 'Логотип Технониколь',
          width: '195',
        },
      ],
      abFormSelectorVariant: '0',
    }
  },
  mounted() {
    this.browser = getBrowser().toLowerCase()

    // при наличии этих значений в адресе отправляем на формы регистрации
    if (
      [
        '#form',
        '#form-webinar',
        '#form-meetings',
        '#form-participant',
      ].includes(location.hash)
    ) {
      location.href = '/user/'
    }

    if (this.$route.query.autotest === '1') {
      this.isAutotest = true
    } else {
      // ab-tests goes here:
      this.abFormSelectorVariant =
        ABTests.getABFormSelectorVariant('ab_meetings_filter')
      this.isABTestEnabled = this.abFormSelectorVariant !== '0'
    }
  },
  methods: {
    showFormSidebar(header, form, params = {}) {
      this.$eventBus.$emit('show-sidebar', {
        form,
        header,
        params,
      })
    },
  },
}
