<template>
  <VMarqueeSliderMTS
    id="marquee-slider"
    :speed="30000"
    :space="40"
    :auto-width="true"
    :class="['common-clients-slider', substrateColor]"
  >
    <img
      v-for="(item, index) in clients"
      :key="index"
      :alt="item.alt"
      :src="item.src"
      :width="item.width"
      height="48"
      class="common-clients-slider__img"
    />
  </VMarqueeSliderMTS>
</template>

<script>
import VMarqueeSliderMTS from '@/components/Common/MTS/VMarqueeSliderMTS'
export default {
  name: 'ClientsSlider',
  components: { VMarqueeSliderMTS },
  props: {
    clients: {
      type: Array,
      default: () => [
        {
          src: require('~/assets/img/clients/clients-bitrix.svg'),
          alt: 'Логотип bitrix',
          width: '187',
        },
        {
          src: require('~/assets/img/clients/clients-lokotech.svg'),
          alt: 'Логотип ЛокоТех',
          width: '189',
        },
        {
          src: require('~/assets/img/clients/clients-hh.svg'),
          alt: 'Логотип hh',
          width: '62',
        },
        {
          src: require('~/assets/img/clients/clients-invitro.svg'),
          alt: 'Логотип invitro',
          width: '184',
        },
        {
          src: require('~/assets/img/clients/clients-avito.svg'),
          alt: 'Логотип avito',
          width: '118',
        },
        {
          src: require('~/assets/img/clients/clients-rusagro.svg'),
          alt: 'Логотип rusagro',
          width: '132',
        },
        {
          src: require('~/assets/img/clients/clients-exmo.svg'),
          alt: 'Логотип эксмо',
          width: '75',
        },
        {
          src: require('~/assets/img/clients/clients-mts.svg'),
          alt: 'Логотип mts',
          width: '62',
        },
        {
          src: require('~/assets/img/clients/clients-samolet.svg'),
          alt: 'Логотип samolet',
          width: '181',
        },
        {
          src: require('~/assets/img/clients/clients-russlimat.svg'),
          alt: 'Логотип Русклимат',
          width: '154',
        },
        {
          src: require('~/assets/img/clients/clients-netology.svg'),
          alt: 'Логотип Нетология',
          width: '214',
        },
        {
          src: require('~/assets/img/clients/clients-optbank.svg'),
          alt: 'Логотип optbank',
          width: '184',
        },
        {
          src: require('~/assets/img/clients/clients-renessans.svg'),
          alt: 'Логотип renessans',
          width: '108',
        },
        {
          src: require('~/assets/img/clients/clients-technonicol.svg'),
          alt: 'Логотип Технониколь',
          width: '195',
        },
      ],
    },
    substrateColor: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.common-clients-slider {
  position: relative;
  padding: 64px 0;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    width: 120px;
    height: 100%;
    z-index: 50;

    @include screen-down('md') {
      width: 90px;
    }
  }

  &::before {
    left: 0x;
  }

  &:after {
    right: 0;
  }

  &.--white {
    &::before {
      background: linear-gradient(
        90deg,
        #fff,
        #fff 49.48%,
        hsla(0, 0%, 100%, 0)
      );
    }

    &:after {
      background: linear-gradient(
        270deg,
        #fff,
        #fff 49.48%,
        hsla(0, 0%, 100%, 0)
      );
    }
  }

  &.--grey {
    &::before {
      background: linear-gradient(
        90deg,
        #f2f3f7 0%,
        #f2f3f7 49.48%,
        rgba(255, 255, 255, 0) 100%
      );
    }

    &::after {
      background: linear-gradient(
        270deg,
        #f2f3f7 0%,
        #f2f3f7 49.48%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }

  &__img {
    height: 48px;
  }
}
</style>
